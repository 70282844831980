<template>
    <section :id="wrapperId" :class="classList">
        <div class="menuSection__header">
            <h5 v-html="name"></h5>
            <p v-if="description" class="h6 text-center" v-html="description"></p>
        </div>
        <div class="menuSection__body">
            <MenuItem v-for="(item, idx) in items" v-bind="item" :index="idx" />
        </div>
    </section>
</template>

<script setup>
import { computed } from 'vue';
import MenuItem from './MenuItem.vue';

const props = defineProps({
    index: { type: Number, required: true },
    name: { type: String, required: true },
    slug: { type: String, required: true },
    items: { type: Array, required: true },
    description: { type: String, default: null },
});

const wrapperId = 'menu-section-' + props.slug;

const classList = computed(() => {
    const classes = ['menuSection'];

    classes.push('has-items-' + props.items.length);
    classes.push('menuSection__' + (props.index + 1));

    if (props.description) classes.push('menuSection__hasDescription');

    return classes;
});
</script>
