<template>
  <div class="location__buttons mx-auto">
    <a
      v-if="isPrivateDining"
      :href="location.private_dining_download"
      class="button button--primary"
      :aria-label="'View ' + location.title + ' Private Dining Menu'"
      target="_blank"
      rel="noopener"
    >
      Private Dining Menu
    </a>

    <a
      v-else
      :href="location.permalink"
      class="button button--primary"
      :aria-label="'View ' + location.title + ' Menu'"
    >
      View Menu
    </a>

    <a
      v-if="location.order_online"
      :href="location.order_online"
      target="_blank"
      rel="noopener"
      class="button button--secondary"
    >
      Order Online
    </a>

    <a v-if="showPrivateDining" :href="'/contact/?show=event&location=' + location.astute_id + '#form'" class="button button--secondary">Plan an Event</a>
    <div v-else-if="location.spoton_id" class="reserveButton__wrapper" ref="reserveButtonWrapper"></div>
  </div>
</template>

<script>
export default {
  /**
   * Static properties.
   */
  props: {
    /**
     * Whether the private dining menu link should be displayed if available.
     */
    showPrivateDining: {
      type: Boolean,
      default: false,
    },

    /**
     * Location attributes.
     */
    location: {
      type: Object,
      required: true,
    },
  },

  /**
   * Computed properties.
   */
  computed: {
    /**
     * Whether to display the private dining menu link instead
     * of the standard menu link.
     *
     * @return {boolean}
     */
    isPrivateDining() {
      return this.showPrivateDining && this.location.private_dining_download;
    },
  },

  /**
   * Component mounted lifecycle hook.
   */
  mounted() {
    if (this.location.spoton_id && !this.showPrivateDining) {
      this.loadReservationsButton();
    }
  },

  /**
   * Component methods.
   */
  methods: {
    /**
     * Dynamically loads the reservations <script> tag
     */
    loadReservationsButton() {
      let script = document.createElement('script');

      script.setAttribute('src', 'https://sdk.seatninja.com/web/sn2.js');

      script.dataset.restaurantId = this.location.spoton_id;
      script.dataset.apiKey = 'lTNqTG5U0g14NrRdoDQ5saWs7RD5itPF5IndrpVE';
      script.dataset.containerCss = 'reserveButton__container';
      script.dataset.buttonCss = 'reserveButton button button--secondary';

      this.$refs.reserveButtonWrapper.appendChild(script);
    },
  },
};
</script>
