import '../styles/seafood.scss';

import { createApp } from 'vue';

import PappasLeadsForm from '@pappas/leads-form';
// import PappasLeadsForm from '../../../pappas-leads-form/src';

import PappasReservationForm from '@pappas/reservation-form/';
// import PappasReservationForm from '../../../pappas-reservation-form/src/js/index.js';

import PappasEclub from '@pappas/eclub';
// import PappasEclub from '../../../pappas-eclub/src/plugin';

import Faqs from './app/components/Faqs.vue';
import LocationButtons from './app/components/LocationButtons.vue';
import LocationMenu from './app/components/LocationMenu/Menu.vue';

import Splide from '@splidejs/splide';

const app = createApp({
    components: {
        Faqs,
        LocationButtons,
        LocationMenu,
    },

    data() {
        return {
            navActive: false,
        };
    },

    computed: {
        reservationFormClasses() {
            return {
                buttons: {
                    primary: 'button button--primary',
                    secondary: 'button button--secondary',
                },
                feedback: {
                    wrapper: 'mb-0',
                    error: 'alert alert-danger mb-0',
                },
            };
        },
    },

    mounted() {
        const opts = {
            arrows: false,
        };

        if (document.querySelector('.homeMasthead')) {
            new Splide('.homeMasthead', opts).mount();
        }
    },

    watch: {
        navActive(value) {
            if(value) {
                document.body.classList.add('nav-active');
            } else {
                document.body.classList.remove('nav-active');
            }
        }
    }
});

app.config.productionTip = false;

app.use(PappasReservationForm);
app.use(PappasLeadsForm);
app.use(PappasEclub);

app.mount('#app');
