<template>
    <div :class="classList">
        <h6 v-html="name"></h6>

        <div v-if="description || (price && price != 0)" class="menuItem__description">
            {{ description }}
            <template v-if="price && price != 0">
                <template v-if="description"> | </template>
                {{ price }}
            </template>
        </div>

        <div v-if="description_alt" class="menuItem__descriptionAlt" v-html="description_alt"></div>

        <div v-if="options && Array.isArray(options)" class="menuItem__options">
            <template v-for="(option, index) in options">
                <template v-if="index"> | </template>
                {{ option.name }} {{ option.price }}
            </template>
        </div>

        <p v-if="!description && !description_alt && !options"></p>
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    name: { type: String, required: true },
    index: { type: Number, required: true },
    description: { type: String, default: null },
    description_alt: { type: String, default: null },
    price: { type: [String, Number], default: null },
    options: { type: Array, default: () => [] },
});

const classList = computed(() => {
    const classes = ['menuItem'];

    classes.push('menuItem__' + (props.index + 1));

    if (props.description || props.price) classes.push('menuItem--hasDescription');
    if (props.description_alt) classes.push('menuItem--hasDescriptionAlt');
    if (props.options) classes.push('menuItem--hasOptions');

    return classes;
});
</script>
