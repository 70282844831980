<template>
    <div class="menuSidebar col-12 col-md-2 offset-md-1 text-center position-relative" data-sticky-container>
        <div class="menuNavWrapper" data-margin-top="40" data-margin-bottom="20">
            <h5 class="mb-2">Menus</h5>
            <div class="menuNav p-3">
                <a v-for="(menu, index) in menus" :href="'#' + menu.slug" :class="{ 'menuNavItem--active': index == active }" class="menuNavItem" @click.prevent="emit('update:menu', index)">{{ menu.label }}</a>
            </div>

            <a v-for="download in filteredDownloads" :href="download.url" target="_blank" rel="noopener" class="menuNavItem--download">
                {{ download.label }} Menu
                <i class="dashicons dashicons-download"></i>
            </a>

            <div v-if="order" class="mt-4">
                <h5 class="mb-2 mt-4">To-Go</h5>
                <div class="menuButtons">
                    <a :href="order" class="button button--secondary">Order Online</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';

const props = defineProps({
    downloads: { type: Object, default: () => ({}) },
    order: { type: String, default: null },
    active: { type: Number, required: true },
});

const emit = defineEmits(['update:menu']);

const menus = inject('menus');

const filteredDownloads = props.downloads.filter((download) => !!download.url);
</script>
