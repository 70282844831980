<template>
    <div id="location-menu-viewport" class="menuWrapper textured container d-md-flex" :id="id">
        <MenuSidebar :downloads="downloads" :order="orderOnline" :active="activeMenu" @update:menu="activeMenu = $event" />

        <div class="menuContent col-md-8 offset-md-1">
            <slot name="banner"></slot>

            <TransitionGroup v-if="menus" name="fade" tag="div">
                <template v-for="(menu, index) in menus" :key="'menu-' + index" :id="menu.slug">
                    <div class="menuSections" v-if="index == activeMenu" :key="'menu-' + index">
                        <MenuSection v-for="section in menu.sections" v-bind="section" :index="index" />
                    </div>
                </template>
            </TransitionGroup>

            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import StickyJs from 'sticky-js';
import { TransitionGroup, computed, onMounted, provide, watch } from 'vue';
import { ref } from 'vue';
import MenuSidebar from './MenuSidebar.vue';
import MenuSection from './MenuSection.vue';

const props = defineProps({
    location: { type: Object, required: true },
    menus: { type: Array, required: true },
    download: { type: String },
    downloadTogo: { type: String },
    downloadCocktails: { type: String },
    downloadHappyHour: { type: String },
    downloadKids: { type: String },
    orderOnline: { type: String },
});

const downloads = [
    { label: 'Download', url: props.download },
    { label: 'Cocktails', url: props.downloadCocktails },
    { label: 'Kids', url: props.downloadKids },
    { label: 'Happy Hour', url: props.downloadHappyHour },
    { label: 'To-Go', url: props.downloadTogo },
];

provide('menus', props.menus);

const id = 'location-menu-' + props.location.id;
const activeMenu = ref(0);
const activeMenuObj = computed(() => props.menus[activeMenu.value]);

watch(activeMenu, (value) => document.getElementById('location-menu-viewport').scrollIntoView());
onMounted(() => new StickyJs('.menuNavWrapper'));

if (window.location.hash) {
    const initialMenuSlug = window.location.replace('#', '');

    function isInitialSlug(menu) {
        return activeMenuObj.value.slug == initialMenuSlug;
    }

    const initialIndex = props.menus.findIndex(isInitialSlug);

    if (initialIndex >= 0) {
        activeMenu.value = initialIndex;
    }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
